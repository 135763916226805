import axiosInstance from "./common/axios-config";

export const UploadDataFile = async (dataFile, fileInfo, importConfigId) => {
  let formData = new FormData();
    formData.append('DataFile', dataFile);
    let uploadUrl = `api/v1/Upload/${importConfigId}`;
  return await axiosInstance.post(uploadUrl, {
          Filename: fileInfo.name,
          DataFile: dataFile
        },
        {
          headers: {
            method: 'POST',
            'Content-Type': 'multipart/form-data' //fileType
          }
        })
        .then(function (response) {
          return true;
        })
        .catch(function (error) {
          console.log(error.message);
          return false;
        });
}

export const GetProcessDetailData = async ({queryKey}) => {
   const [, id] = queryKey;
   const {data} = await axiosInstance.get(`/api/v1/ImportConfig/${id}`);
   return data;
}

export const GetProcessesData = async ({queryKey}) => {
  const {data} = await axiosInstance.get('/api/v1/ImportConfig/');
  return data;
}

export const GetProcessInfoData = async ({queryKey}) => {
  const [, id, page, rows] = queryKey;
  const {data} = await axiosInstance.get(`/api/v1/JobInfo/${id}?page=${page}&pageSize=${rows}`); 
  return data;
}

export const GetProcessErrorsData = async ({queryKey}) => {
    const [, id] = queryKey;
    const {data} = await axiosInstance.get(`/api/v1/JobInfo/${id}/errors`);
    return data;
}
