import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { templatePaginatorLogInfo } from "../common/helpers/Helper";
import "../process-detail/ProcessDetail.scss";
import {useQuery} from "react-query";
import {GetProcessErrorsData} from "../../services/processes-service";
import {noRefetchOnWindowFocus} from "../../services/common/useQuery-config";

const MAX_LENGTH = 100;

const RowErrorDialog = ({ errorId, visible, onHide, processName, fileName }) => {

  const { data: processErrors } = useQuery(
      ["processErrors", parseInt(errorId)],
      GetProcessErrorsData,
      noRefetchOnWindowFocus
  );

  const itemTemplate = (rowData, colData) => {
    const fullMessage = rowData[colData.field];
    const id =
      colData.field === "message"
        ? `tooltip-message-${rowData.id}`
        : `tooltip-rowdata-${rowData.id}`;
    const truncatedMessage =
      fullMessage.length > MAX_LENGTH
        ? `${fullMessage.substring(0, MAX_LENGTH)}...`
        : fullMessage;

    return (
      <div style={{ overflow: 'hidden'}}>
        <span
          id={id}
          data-pr-tooltip={fullMessage}
          data-pr-position="top"
          style={{ cursor: "pointer"}}
        >
          {truncatedMessage}
        </span>
        {fullMessage.length > MAX_LENGTH && (
          <Tooltip
            target={`#${id}`}
            style={{ maxWidth: "800px", whiteSpace: "normal" }}
          />
        )}
      </div>
    );
  };

  return (
    <Dialog
      header={`${processName} > ${fileName}`}
      visible={visible}
      onHide={onHide}
    >
      {processErrors ? (
      <DataTable
        key="errors-datatable"
        value={processErrors}
        paginator={processErrors.length > 0}
        paginatorTemplate={templatePaginatorLogInfo}
        rows={10}
        className="datatable-gridlines"
      >
        <Column
          field="message"
          header="Validation Errors"
          body={itemTemplate}
        />
        <Column field="rowData" header="Row Data" body={itemTemplate} />
      </DataTable>) : null}
    </Dialog>
  );
};

RowErrorDialog.propTypes = {
  errorId: PropTypes.number,
  visible: PropTypes.bool,
  onHide: PropTypes.func,
  processName: PropTypes.string,
  fileName: PropTypes.string,
};

export default RowErrorDialog;